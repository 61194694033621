.status-window {
  position: relative;
  padding-top: 0.625rem;
  height: 600px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: start;
}

.spinner-group {
  position: absolute;
  top: 275px;
  left: calc(50% - 48px);
  height: 50px;
  width: 100px;
}
