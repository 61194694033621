.main-form {
  padding-top: 0.625rem;
}

.btn-adjust {
  height: calc(3.5rem + 2px) !important;
  width: 100% !important;
  padding: 1rem 0.75rem !important;
}

.select-tip {
  display: block;
  text-align: start !important;
  color: #842029;
}

.hide {
  display: none !important;
}
